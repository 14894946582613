export const displaySize = () => {
  let screen = "large";
  if (window.screen.width < 600) {
    screen = "phone";
  } else if (window.screen.width < 900) {
    screen = "tablet";
  } else if (window.screen.width > 1800) {
    screen = "xlarge";
  }
  return screen;
};
