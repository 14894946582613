<template>
    <div  class="side__container" :class="{'side__container--hide': !display, 'side__container--show': display}">
            <button class="side__quit" aria-label="close side menu" @click="hide">
                <Svg icon="delete" className="side__quit--svg"/>
            </button>
            <div class="side__logo">
                <img src="/img/logo/logo-c4water-white.svg" width="300" height="124" alt="logo c4water blanc">
            </div>
            
            <div class="side">
                <h2>Menu</h2>
                <ul>
                    <li v-for="s in nav" :key="s.name">
                        <router-link :to="s.section ? {name:s.name, params: {section:s.section}}: {name:s.name}" class="side__btn" @click="hide">
                            {{s.title}}
                        </router-link>
                        <ul v-if="s.hover" class="side__subnav">
                            <li v-for="sub of s.subnav" class="side__sub">
                                <router-link :to="{name:s.name, params:{page: sub.page}}" @click="hide">
                                    {{sub.name}}
                                </router-link>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
            <a href="mailto:info@c4water.com" class="side__contact">
                <Svg className="side__contact--svg" icon="email"/> info@c4water.com
            </a>
                <a href="tel:003268336048" class="side__contact">
                <Svg className="side__contact--svg" icon="phone"/> +32 68 33 60 48
            </a>
        </div>
</template>

<script>
import { ref, watch } from '@vue/runtime-core'
import Svg from '@/components/utils/Svg';

export default {
    components:{
        Svg
    },
    props:[ 'navigation', 'displaySide', 'hide'],
    setup(props){
        const display = ref(props.displaySide);
        const nav = props.navigation;

        watch(() => props.displaySide, (n, o) => {
            display.value = n;
        })

        return{display, nav}
    }
}
</script>

<style lang='scss'>

@import '@/style/main';
.side{
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;


    & h2{
        text-transform: uppercase;
        letter-spacing: .1;
        margin-bottom: 2.4rem;
        font-size: 3rem;
        letter-spacing: .2rem;

        @include respond(phone){
            margin-bottom: 1rem;
            font-size: 2rem;
        }
    }

    & li{
        
        list-style: none;
        width: 100%;  
        position: relative;
    }

    &__btn{
        @include buttonNoStyle;
        position: relative;
        display: flex;
        padding: .5rem 2.4rem;
        width: 100%;
        text-transform: uppercase;
        font-weight: 400;
        font-size: 1.6rem;
        letter-spacing: .1rem;

         &::before{
            color:inherit;
            position: absolute;
            content: "";
            top: 50%;
            left: 0rem;
            transform: translate(0%, -50%);
            height: .6rem;
            border:.6rem solid transparent;
            border-left-color: $color-white;

            // background-color: $color-white;
        }  

        &:hover{
            background-color: $color-white;
            color:$color-primary;
        }

        @include respond(phone){
            font-size: 1.5rem;
            padding: .6rem 1.8rem;
            letter-spacing: 1px;

        }
    }

    &__subnav{
        display: flex;
        flex-direction: column;

        
    }

    &__sub{
        display: flex;
        justify-content: flex-start;
        padding-bottom: .5rem;
        padding-left: 3rem;
        & a{
            text-align: right;
            @include buttonNoStyle;
            @include hoverUnderline;
            
            text-transform: none;
            font-weight: 300;
                &::before{
                    position: absolute;
                    content:"";
                    background-color: $color-white;
                    width: .7rem;
                    height: .7rem;
                    top: 50%;
                    left: -2rem;
                    transform: translateY(-50%);
                }
             @include respond(phone){
                font-size: 1.5rem;
                padding: .4rem 1.4rem;
                letter-spacing: 1px;

            }
        }
    }

     &__container{
        position: fixed;
        z-index: 1000;
        top: 0;
        width: 30vw;
        height: 100vh;
        background-color: $color-primary;
        padding: 4rem;
        display: flex;
        flex-direction: column;
        color: $color-white;

        &--hide{
            transition: all .4s;
            right: -100vw;
        }

        &--show{
             transition: all .4s;
            right: 0vw;
        }
         @include respond(tab-land){
            width: 50vw;
            padding: 3rem;
        }
        @include respond(phone){
            width: 100vw;
            padding: 3rem;
        }
        
    }

    &__logo{
        width: 17rem;
        margin: 0 auto;
        & img{
            width: 100%;
            height: auto;
        }
    }

    &__quit{
        @include buttonNoStyle;
        position: absolute;
        top: 2rem;
        right: 2rem;

        transition: all .2s;

        &--svg{
            @include svg(2.4rem);
        }

        &:hover{
            transform: scale(1.1);
        }
    }

    &__contact{
        text-decoration: none;
        color:$color-primary;
        letter-spacing: 0rem;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-size: 1.2rem;

        padding: .7rem 2rem;
        margin-bottom: 0rem;
        border-radius: 3px;
        background-color: $color-white;
        transition: all .2s;

        &:hover{
            transform: scale(1.05);
        }

        &:not(:last-child){
            margin-bottom: 1rem;
        }

        &--svg{
            @include svg(2rem);
            margin-right: 2rem;
        }
    }
}

</style>