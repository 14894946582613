<template>
<header id="header">
    <Banniere/>
    <Navigation :nav="navigation"/>
</header>

</template>

<script>

import Banniere from "@/components/Banniere"
import Navigation from "@/components/Navigation"

export default {
  props:['navigation'],
  components:{
    Banniere,
    Navigation
  },
}
</script>

<style lang='scss'>
@import '@/style/main';


header{
  position: fixed;
  top: 0;
  z-index:  1000;
  width: 100%;

      border-bottom: .1rem solid $color-primary;
    box-shadow: $shadow;
}
</style>