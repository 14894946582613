<template>
  <div v-if="data" class="av">
      <h3>
          {{ data.title }}
      </h3>
      <div class="av__list">
          <div v-for="av of data.feature" class="av__list--item">
              <div class="av__list--svg">
                  <Svg :icon="av.svg" size="4"></Svg>
              </div>
              {{av.label}}
          </div>
      </div>
      <router-link :to="{name:'Technology'}" class="av__cta btn__link">
          {{data.cta}} <span class="btn__link--span"><Svg icon="arrow-link" size="2"></Svg></span>
      </router-link>
  </div>
</template>

<script>
import {getData} from '@/composables/utils/getData';

import Svg from '@/components/utils/Svg';
import { useStore } from 'vuex';
import { onMounted, ref } from '@vue/runtime-core';

export default {
    components:{
        Svg
    },
    setup(){
        const store = useStore();
        const data  =ref(null);
        
        onMounted(async () => {
            data.value = await getData('/advantages');
            console.log(data.value);
        })

        return {data}
    }
}
</script>

<style lang='scss'>
@import '@/style/main';

.av{
    position: relative;
    border: 1px solid currentColor;
    color:$color-primary;
    box-shadow: $shadow;
    border-radius: 5px;

    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 3rem;

    & h3{
        font-size: 1.8rem;
        margin-bottom: 3rem;
    }

    &__list{
        display: flex;

        @include respond(phone){
            flex-wrap: wrap;
            justify-content: space-between;
        }

        &--item{
            display: flex;
            flex-direction: column;
            align-items: center;

            font-size: 1.6rem;
            text-transform: uppercase;
            

            &:not(:last-child){
                margin-right: 4rem;
                 @include respond(phone){
                    margin-right: 0;
                }


            }
            
            @include respond(phone){
                flex: 0 0 48%;
                // margin: 2rem;
                margin-bottom: 2rem;
            }
        }

        &--svg{
            background-color: $color-primary;
            color:$color-white;
            border-radius: 50%;
            width: 8rem;
            height: 8rem;

            display: flex;
            align-items: center;
            justify-content: center;

            margin-bottom: 2rem;
        }
    }

    &__cta{
        position: absolute;
        bottom: 1rem;
        right: 2rem;
    }
}


</style>