<template>
  <div class="equation">
      <div class="equation__side">
          <template v-for="(i, e) of equationLeft">
            <div class="equation__item equation__item--bullet">
                <Svg :icon="i.svg" :size="i.size"/>
                {{i.text}}
            </div>
            <div v-if="e+1 < equationLeft.length" class="equation__item equation__item--plus">
                <Svg icon="plus" size="4"/>
            </div>
          </template>
      </div>
      <img class="equation__equal" src="/img/gallery/home/equal-c4w.png" alt="">
      <div class="equation__side">
          <template v-for="(i, e) of equationRight">
            <div class="equation__item equation__item--bullet">
                <Svg :icon="i.svg" :size="i.size"/>
                {{i.text}}
            </div>
            <div v-if="(e+1) < equationRight.length" class="equation__item equation__item--plus">
                <Svg icon="plus" size="4"/>
            </div>
          </template>
      </div>
  </div>
</template>

<script>
import Svg from '@/components/utils/Svg';
export default {
    props:['content'],
    components:{
        Svg
    },
    setup(props){
        const equationLeft = props.content.equation.filter(el => el.side === 'left');
        const equationRight = props.content.equation.filter(el => el.side === 'right');

        return {equationLeft, equationRight}
    }
}
</script>

<style lang="scss">
@import '@/style/main';
.equation{
    margin: 4rem 0;
    display: flex;
    justify-content: center;
    align-items: center;

    @include respond(phone){
        flex-direction: column;
    }
    &__side{
        display: flex;
        align-items: center;

        @include respond(phone){
            flex-direction: column;
        }
    }

    &__item{
        &:not(:last-child){
            margin-right: 3rem;
            @include respond(phone){
                margin: 0;
                margin-bottom: 2rem;
            }
        }

        &--plus{
            color:$color-primary;
        }

        &--bullet{
            color:$color-white;
            height: 16rem;
            width: 16rem;
            border-radius: 50%;
            background-color: $color-primary;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            text-transform: uppercase;
            letter-spacing: .1rem;
            font-size: 1.4rem;
            box-shadow: $shadow;

            @include respond(phone){
                height: 15rem;
                width: 15rem;
                font-size: 1.4rem;
            }

            & svg{
                margin-bottom: 1rem;
            }
}
    }

    &__equal{
        height: 14rem;
        margin: 0 6rem;    

        @include respond(phone){
                margin: 3rem 0;
            }
    }
}
</style>