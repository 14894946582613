export const languages = () => {
  const lang = [
    {
      name: "English",
      locale: "en",
    },
    {
      name: "Français",
      locale: "fr",
    },
  ];
  return lang;
};
