<template>
  <carousel-nav :length="testimony.length"></carousel-nav>
  <div id="carousel-testimony" class="carousel u-margin-bottom-large">
      <div v-for="testi of testimony" class="carousel__item testi">
          <Svg icon="quote" size="8" color="#DFF5FF"/>
          <p>
              {{testi.text}}
          </p>
          <div class="testi__author">
              <div class="testi__author--content">
                  <h4>{{testi.author}}</h4>
                  <h5>{{testi.role}}</h5>
              </div>
              <img :src="`/img/gallery/testimony/${testi.img}`" alt="">
          </div>
      </div>
  </div>

    <h3 class="section-subsubtitle">Ils nous ont fait confiance</h3>
    <div class="trust">
        <div v-for="t of trust" class="trust__item">
            <img :src="`/img/gallery/partenaires/${t.img}`" alt="">
        </div>
    </div>
</template>

<script>
import {displaySize} from '@/composables/utils/displaySize';
import $ from 'jquery';
import slick from 'slick-carousel';


import CarouselNav from '@/components/carousel/CarouselNav';
import Svg from '@/components/utils/Svg';
import { onMounted } from '@vue/runtime-core';

export default {
    components:{
        CarouselNav,
        Svg
    },
    setup(){
        const screen = displaySize();

        let numberTestimony = 3;

        if(screen === 'phone'){
            numberTestimony = 1;
        }
    

         onMounted(() => {
            $("#carousel-testimony").slick({
                infinite: false,
                slidesToShow: numberTestimony,
                slidesToScroll: 1,
                rows:0,
                prevArrow: $("#prev"),
                nextArrow: $("#next"),
            });
        })


        const testimony = [
            {
                text:"Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quas inventore, animi labore ad pariatur ab laudantium sapiente provident officiis culpa cumque nulla ducimus blanditiis! Ratione similique natus nihil tempora ea, fugiat distinctio quibusdam perspiciatis eos! Voluptatibus adipisci corrupti voluptates quasi asperiores maiores, est autem incidunt, porro pariatur et harum esse quam fuga itaque, nobis reiciendis quidem libero in? Accusantium, nostrum!",
                author:"John Doe",
                role:"Gérant de piscines",
                img:"john.jpg"
            },
            {
                text:"Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quas inventore, Ratione similique natus nihil tempora ea, fugiat distinctio quibusdam perspiciatis eos! Voluptatibus adipisci corrupti voluptates quasi asperiores maiores, est autem incidunt, porro pariatur et harum esse quam fuga itaque, nobis reiciendis quidem libero in? Accusantium, nostrum!",
                author:"John Doe",
                role:"Gérant de piscines",
                img:"john.jpg"
            },
            {
                text:"Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quas inventore, animi labore ad pariatur ab laudantium sapiente provident officiis culpa cumque nulla ducimus blanditiis! Ratione similique natus nihil tempora ea, fugiat distinctio quibusdam perspiciatis eos! Voluptatibus adipisci corrupti voluptates quasi asperiores maiores, est autem incidunt, porro pariatur et harum esse quam fuga itaque, nobis reiciendis quidem libero in? Accusantium, nostrum!",
                author:"John Doe",
                role:"Gérant de piscines",
                img:"john.jpg"
            },
            {
                text:"Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quas inventore, animi labore ad pariatur ab laudantium sapiente provident officiis culpa cumque nulla ducimus blanditiis! Ratione similique natus nihil tempora ea, fugiat distinctio quibusdam perspiciatis eos! Voluptatibus adipisci corrupti voluptates quasi asperiores maiores, est autem incidunt, porro pariatur et harum esse quam fuga itaque, nobis reiciendis quidem libero in? Accusantium, nostrum!",
                author:"John Doe",
                role:"Gérant de piscines",
                img:"john.jpg"
            }
        ];

        const trust = [
            {
                img:'coca-cola.jpg'
            },{
                img:'coca-cola.jpg'
            },{
                img:'coca-cola.jpg'
            },{
                img:'coca-cola.jpg'
            },{
                img:'coca-cola.jpg'
            }
            
        ]
        return {testimony, trust}
    }
}
</script>

<style lang='scss'>
@import '@/style/main';
@import '@/style/components/carousel';
@import '@/style/components/slick';



.testi{
    width: 31%;
    // align-self: flex-start;
    padding: 2rem;
    border: 1px solid $color-primary;
    border-radius: 5px;
    box-shadow: $shadow;
    align-self: flex-start;

    display: flex;
    flex-direction: column;

    & p{
        margin: 2rem 0;
        text-align: justify;
    }

    &__author{
        align-self: flex-end;

        display: flex;
        align-items: center;
        & img{
            width: 4rem;
            height: 4rem;
            border-radius: 50%;
            margin-left: 2rem;
        }

        &--content{
            text-align: right;

            & h4{
                font-weight: 700;
                font-size: 1.6rem;
            }

            & h5{
                font-weight: 400;
            }
        }
    }
    
}

</style>