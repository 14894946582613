<template>
   <div class="faq__item">   
        <div class="faq__q">
            <div class="faq__q--text">
                {{q}}
            </div>
            
            <button class="faq__btn" :class="{'faq__btn--open': display}"  @click.prevent="toggleFAQ()">
                <Svg icon="plus" size="2.5"></Svg>
            </button>
        </div>
        <div class="faq__a" :class="{'u-hide': !display}" v-html="answer"></div>
    </div>
</template>

<script>
import Svg from '@/components/utils/Svg';

import showdown from 'showdown';

import {ref} from 'vue';

export default {
    props:['q', 'a'],
    components:{
        Svg
    },
    setup(props){
        const display = ref(false)
        const toggleFAQ = () => {
            display.value = !display.value;
        }

          let converter = new showdown.Converter({
                emoji: true,
                noHeaderId: true,
            }); 

            const answer = converter.makeHtml(props.a)
            

        return {
            answer, display, toggleFAQ
        }
    }
}
</script>

<style lang='scss'>
@import '@/style/main';
.faq{
    &__item{
        cursor: pointer;
        background-color: $color-white;
        border: 1px solid $color-primary;
        border-radius: 5px;

        display: flex;
        flex-direction: column;
        padding:  1.2rem 2rem;

        &:not(:last-child){
            margin-bottom: 1.5rem;
        }
    }

    &__btn{
        @include buttonNoStyle;
        margin-left: auto;
        display: flex;
        align-content: center;
        color:$color-primary;

        transition: all .2s;
        &:hover{
            transform: scale(1.1);
        }

        &--open{
            transform: rotate(45deg);
            &:hover{
                transform: scale(1.1)  rotate(45deg);
            }
        }
    }

    &__q{
        width: 100%;
        display: flex;
        align-items: center;
        font-weight: 700;
        &--text{
            flex: 0 0 85%;
        }
    }

    &__a{
        margin-top: 2rem;
        & p{
            &:not(:last-child){
                margin-bottom: 2rem;
            }
        }

        & a{
            @include buttonNoStyle;
            @include hoverUnderline;
            color:$color-primary;
        }
    }
}

</style>