<template>
  <div class="calc">
    <div class="calc__form">
      <div v-for="i of form" class="calc__form--item">
        <h4 class="calc__form--title">
          {{i.title}}
        </h4>
        <div class="calc__form--radio">
          <label v-for="j of i.radio" :for="j.value" class="calc__form--choice">
            <input type="radio" :id="j.value" :name="i.slug" :value="j.value" :checked="j.selected">
            <div class="calc__form--mark"></div>
            <span >{{j.label}}</span>
          </label>
        </div>
      </div>
      <div class="calc__form--item">
        <h4 class="calc__form--title">
          {{text[lang].vol.title}}
        </h4>
        <h5 v-if="errorDisplay" class="calc__form--error">
          {{text[lang].vol.err}}
        </h5>
        <div class="calc__form--vol">
          <div class="calc__form--volume">
          <input v-model="volume" type="text" :placeholder=" text[lang].vol.phVol" @keypress="checkInputCalculator($event, 'volume')">
          m³
        </div>
         <span>ou</span>
         <div class="calc__form--volume">
          <input v-model="debit" type="text" :placeholder=" text[lang].vol.phDeb" @keypress="checkInputCalculator($event, 'debit')">
          m³/h
        </div>
        </div>
        
      </div>
      <div class="calc__form--item calc__form--cta">
        <button class="btn btn--primary" @click="computeEconomy">
          {{text[lang].cta.title}}
        </button>
      </div>
    </div>
    <div class="calc__result">
      <div class="calc__result--container">
        <div class="calc__result--content">
          <div class="calc__result--item">
            <Svg icon="money" size="8"/>
            <div class="calc__result--features">
              <h4>
                <span>{{money}}</span> €
              </h4>
              <h5>
                {{text[lang].cta.eur}}
              </h5>
            </div>
          </div>
          <div class="calc__result--item">
            <Svg icon="co2" size="8"/>
            <div class="calc__result--features">
              <h4>
                <span>{{co2}}</span> kg
              </h4>
              <h5>
                {{text[lang].cta.co2}}
              </h5>
            </div>
      
          </div>
        </div>  
        <div class="calc__result--cta">
          <a class="btn btn--white" href="mailto:info@c4water.com">{{text[lang].cta.quote}}</a>
        </div>
      </div>
      <div class="calc__result--disclosure">
          {{text[lang].cta.ps}}
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery';

import Svg from '@/components/utils/Svg'
import { ref } from '@vue/reactivity';
import { useStore } from 'vuex';
export default {
  components:{
    Svg
  },
  setup(){
    const store = useStore();

    const lang = store.state.language;

    const text= {
      en:{
        sector:{
          title:"Sector",
          eau:"Drinking Water",
          piscine:"Pool treatment",
          autre:"Others"
        },
        type:{
          title:"Current system type",
          cl:"Chlorine tablets",
          jav:"Liquid Bleach"
        },
        localisation:{
          title:"Location",
          eu:"Europe",
          af:"Africa"
        },
        vol:{
          title:"Treatment volume/flow rate",
          phVol:"Your water volume",
          phDeb:"Your flow",
          err:"You must enter a volume or a flow rate to calculate your savings"
        },
        cta:{
          title:"Compute my potential savings",
          eur:"saved per year",
          co2:"of CO2 saved per year",
          quote:"Request your quote here!",
          ps:"This is an automatic estimate, let's talk about it in more detail by email"
        }
      },
      fr:{
        sector:{
          title:"Secteur",
          eau:"Eau Potable",
          piscine:"Traitement Piscine",
          autre:"Autres"
        },
        type:{
          title:"Type de système actuel",
          cl:"Pastilles de Chlore",
          jav:"Javel Liquide"
        },
        localisation:{
          title:"Afrique",
          eu:"Europe",
          af:"Afrique"
        },
        vol:{
          title:"Volume de traitement/débit",
          phVol:"Votre volume d'eau",
          phDeb:"Votre débit",
          err:"Vous devez rentrer un volume ou un débit pour calculer vos économies"
        },
        cta:{
          title:"Calculer mes économies potentielles",
          eur:"économisés par an",
          co2:"de CO2 économisés par an",
          quote:"Demandez votre devis ici !",
          ps:"Ceci est une estimation automatique, parlons en plus en détails par email"
        }
      }
    }


    const errorDisplay = ref(false);
    const debit = ref(null);
    const volume = ref(null);

    const co2 = ref(0);
    const money = ref(0);

    

    const getDataForm = () => {
      const data = {
        sector: $("input[name='sector']:checked").val(),
        system: $("input[name='system']:checked").val(),
        localisation: $("input[name='localisation']:checked").val(),
        debit: debit.value,
        volume: volume.value,
      }
      return data;
    }

    const computeEconomy = () => {

      const data = getDataForm();

      const quantiteTraiter = data.volume ? data.volume : data.debit * 24; // unités m3

      if(!quantiteTraiter){
        errorDisplay.value = true;
        co2.value = 0;
        money.value = 0;
        return false;
      }

      

      // Constantes
      const coutMachine = 0; //euros
      const contenuco2 = data.system === 'javel' ? 2.9 : 5.4;
      const co2c4w = 1.7;
      const prixC4w = 1.5;

      const prixChloreAfrica = {
        debit_100: 25, // debit <100
        debit_500: 10,  // debit < 500
        debit_1000: 5 // debit >500
      } 

      const prixChloreEurope= {
        debit_100: 20,
        debit_500: 7,
        debit_1000: 5
      }


      const prixChloreData = data.localisation === 'europe' ? prixChloreEurope : prixChloreAfrica

      const demandeChlore = data.sector === 'piscine' ? quantiteTraiter > 100 ? 20 : 10 : 2;

      const prixChlore = quantiteTraiter < 100 ? prixChloreData.debit_100 : quantiteTraiter < 500?  prixChloreData.debit_500 :  prixChloreData.debit_1000;
      
      const besoinChlore = quantiteTraiter * 350 * demandeChlore;

      const gainOperationnel = quantiteTraiter * demandeChlore * 0.35 *(prixChlore - prixC4w);
      

      co2.value = (contenuco2 - co2c4w) * besoinChlore / 1000; //gr to kilos
      money.value = ((gainOperationnel * 10) - coutMachine)/10;

    }

    const  checkInputCalculator = (evt, inputName) => {
      errorDisplay.value = false;
      if(inputName === 'debit') volume.value = '';
      if(inputName === 'volume') debit.value = '';

      // We prevent entering any letter

      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();;
      } else {
        return true;
      }
    }

    const form = [
      {
        type:'radio',
        title: text[lang].sector.title,
        slug:"sector",
        radio:[
          {
            label:text[lang].sector.eau,
            value:"potable",
            selected: true
          },
          {
            label:text[lang].sector.piscine,
            value:"piscine",
            selected: false
          },
          {
            label:text[lang].sector.autre,
            value:"autre",
            selected: false
          }
        ]
      },
       {
        type:'radio',
        title: text[lang].type.title,
        slug:"system",
        radio:[
          {
            label:text[lang].type.cl,
            value:"chlore",
            selected: true
          },
          {
            label:text[lang].type.jav,
            value:"javel",
            selected: false
          }
        ]
      },
       {
        type:'radio',
        title: text[lang].localisation.title,
        slug:"localisation",
        radio:[
          {
            label:text[lang].localisation.eu,
            value:"europe",
            selected: true
          },
          {
            label:text[lang].localisation.af,
            value:"africa",
            selected: false
          },
        ]
      }
    ];

    return {
      co2, 
      computeEconomy,
      debit, 
      errorDisplay, 
      lang,
      form,
      checkInputCalculator,  
      money,
      text,
      volume
    }
  }
}
</script>

<style lang="scss">
@import '@/style/main';

.calc{
  display: flex;
  @include respond(phone){
      flex-direction: column;
      margin-bottom: 4rem;
    }

  &__form{
    flex: 0 0 55%;

    


    &--radio{
      display: flex;
        @include respond(phone){
          flex-direction: column;
          margin-bottom: 2rem;
        }

    }

    &--error{
      color:$color-red;
      font-size: 1.4rem;
      margin-bottom: 1rem;
    }

    &--vol{
      display: flex;
      align-items: center;

      @include respond(phone){
          flex-direction: column;
          align-items: flex-start;
        }

      & span{
        margin: 0 4rem;

        @include respond(phone){
          margin: 0;
          margin-bottom: 1rem;
      }
      }
    }

    &--cta{
      margin-top: 4rem;
    }

    &--volume{
      display: flex;
      align-items: center;
      font-weight: 700;
      text-transform: uppercase;
      font-size: 2rem;

      @include respond(phone){
          margin-bottom: 1rem;
      }

  

      & input{
        padding: 1em;
        margin-right: 1rem;
        outline: none;
        box-shadow: $shadow;
        border: 1px solid $color-primary;
        border-radius: 5px;
      }
    }

    &--item{
      margin-bottom: 3rem;
      & h4{
        font-size: 1.8rem;
        text-transform: uppercase;
        margin-bottom: 1rem;
      }
    }

    &--choice:hover  > &--mark {
        background-color: $color-primary;
        &::after {
            display: block;
            color: $color-white;
        }
    }

    &--choice{
      display: flex;
      align-items: center;
      cursor: pointer;

      @include respond(phone){
          margin: 0;
          margin-bottom:1rem;
        }

      &:not(:last-child){
        margin-right: 6rem;
      }

        & input{
          display: none;
        }

        & span{
          text-transform: uppercase;
          cursor: pointer;
        }
    }

    &--choice input:checked + &--mark {
            background-color: $color-primary;
            &::after {
                display: block;
                color: $color-white;
            }
        }
     &--mark{
         position: relative;
            height: 2.5rem;
            width: 2.5rem;
            background-color: $color-white;
            margin-right: 1.3rem;
            border: 1px solid $color-grey-50;
            cursor: pointer;

            &::after {
                content: "";
                position: absolute;
                display: none;
                content: "\2713";
                font-size: 1.8rem;
                top: 48%;
                left: 50%;

                transform: translate(-50%, -50%);
            }
      }
  }

  &__result{
    flex: 1;
    margin-left: 3rem;
    @include respond(phone){
          margin-left: 0;
      }

    &--container{
      color:$color-white;
      background: linear-gradient(to right bottom, $color-primary, #74B6CB);
      padding: 2rem;
      border-radius: 5px;
    }

    &--disclosure{
      font-size: 1.2rem;
      text-align: right;
      margin-top: .5rem;
    }

    &--content{
      margin-bottom: 2rem;
    }

    &--item{
      display: flex;

      & svg{
        margin-right: 4rem;
      }
      &:not(:last-child){
        margin-bottom: 2rem;
      }
    }

    &--features{
      display: flex;
      flex-direction: column;

      & h4{
        font-size: 4rem;
      }

      & h5{
        font-size: 1.4rem;
        font-weight: 400;
      }
    }
  }
}

</style>